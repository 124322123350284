var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-modal', {
    attrs: {
      "id": "organizer-tournament-participant-modal",
      "no-close-on-backdrop": "",
      "size": "lg",
      "title": "Add participants"
    },
    on: {
      "hidden": _vm.onHidden
    },
    scopedSlots: _vm._u([{
      key: "modal-footer",
      fn: function fn(_ref) {
        var cancel = _ref.cancel;
        return [_c('b-row', {
          staticClass: "w-100 mr-1 ml-1 justify-content-start"
        }, [_c('dismissible-message-box', {
          attrs: {
            "auto-dismiss": 6000,
            "show": _vm.showMessage,
            "variant": _vm.notificationMessageType
          }
        }, [_c('feather-icon', {
          staticClass: "mr-50",
          attrs: {
            "icon": "InfoIcon"
          }
        }), _vm._v(" " + _vm._s(_vm.message) + " ")], 1)], 1), _c('div', {
          staticClass: "row justify-content-end w-100 mr-1 ml-1"
        }, [_c('b-button', {
          attrs: {
            "variant": "outline-primary"
          },
          on: {
            "click": cancel
          }
        }, [_vm._v(" Close ")])], 1)];
      }
    }])
  }, [_c('b-container', [_c('p', [_vm._v("Add participants to the tournament. Use email to invite participants to the platform so they can edit and modify their profile. Email is not required. You can later remove participants and re-add if you made a mistake.")]), _c('h5', [_vm._v("Add single participant")]), _c('validation-observer', {
    ref: "singleParticipantObserver"
  }, [_c('b-row', [_c('b-col', {
    attrs: {
      "md": "12",
      "lg": "5"
    }
  }, [_c('b-form-group', [_c('label', {
    attrs: {
      "for": "name"
    }
  }, [_vm._v(" " + _vm._s(_vm.participantType === _vm.TournamentParticipantType.player ? 'Player name ' : 'Team name') + " ")]), _c('validation-provider', {
    attrs: {
      "name": "name",
      "rules": "required"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref2) {
        var componentErrors = _ref2.errors;
        return [_c('b-form-input', {
          attrs: {
            "placeholder": _vm.participantType === _vm.TournamentParticipantType.team ? 'Team name' : 'Player name',
            "name": "name"
          },
          on: {
            "change": function change($event) {
              _vm.participant.participant_id = null;
            }
          },
          model: {
            value: _vm.participant.name,
            callback: function callback($$v) {
              _vm.$set(_vm.participant, "name", $$v);
            },
            expression: "participant.name"
          }
        }), _c('small', {
          staticClass: "text-danger"
        }, [_vm._v(_vm._s(componentErrors[0]))])];
      }
    }])
  })], 1)], 1), _c('b-col', {
    attrs: {
      "md": "12",
      "lg": "5"
    }
  }, [_c('b-form-group', [_c('label', {
    attrs: {
      "for": "email"
    }
  }, [_vm._v("Email:")]), _c('validation-provider', {
    attrs: {
      "name": "email",
      "rules": _vm.emailRules
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref3) {
        var componentErrors = _ref3.errors;
        return [_c('b-form-input', {
          attrs: {
            "name": "email",
            "placeholder": "john@example.com"
          },
          model: {
            value: _vm.participant.email,
            callback: function callback($$v) {
              _vm.$set(_vm.participant, "email", $$v);
            },
            expression: "participant.email"
          }
        }), _c('small', {
          staticClass: "text-danger"
        }, [_vm._v(_vm._s(componentErrors[0]))])];
      }
    }])
  })], 1)], 1)], 1)], 1), _c('b-row', [_c('b-col', {
    attrs: {
      "lg": "3"
    }
  }, [_c('b-form-checkbox', {
    attrs: {
      "id": "invitation-single",
      "name": "checkbox-1"
    },
    model: {
      value: _vm.sendEmailInvitation,
      callback: function callback($$v) {
        _vm.sendEmailInvitation = $$v;
      },
      expression: "sendEmailInvitation"
    }
  }, [_vm._v(" Send invitation? ")])], 1), _c('b-col', [_c('b-form-group', [_c('spinning-button', {
    attrs: {
      "is-loading": _vm.isLoadingSingle,
      "type": "submit",
      "variant": "primary"
    },
    nativeOn: {
      "click": function click($event) {
        return _vm.handleSubmitSingle($event);
      }
    }
  }, [_vm._v(" Add participant ")])], 1)], 1)], 1), _c('b-row', {
    staticClass: "mt-2"
  }, [_c('b-col', [_c('h5', [_vm._v("Bulk add participants")])])], 1), _c('validation-observer', {
    ref: "bulkParticipantObserver"
  }, [_c('b-row', [_c('b-col', [_c('b-form-group', [_c('label', {
    attrs: {
      "for": "bulkParticipants"
    }
  }, [_vm._v("Participants:")]), _c('validation-provider', {
    attrs: {
      "name": "participants"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref4) {
        var componentErrors = _ref4.errors;
        return [_c('p', [_vm._v("Participants name is mandatory and you can also add participants email by separating name and email by coma.")]), _c('p', [_vm._v("Example: TEAM A,winners@gmail.com")]), _c('textarea', {
          directives: [{
            name: "model",
            rawName: "v-model",
            value: _vm.bulkParticipants,
            expression: "bulkParticipants"
          }],
          staticClass: "form-control",
          attrs: {
            "rows": "5",
            "placeholder": "Add one participant per line"
          },
          domProps: {
            "value": _vm.bulkParticipants
          },
          on: {
            "input": function input($event) {
              if ($event.target.composing) { return; }
              _vm.bulkParticipants = $event.target.value;
            }
          }
        }), _vm._v(" "), _c('small', {
          staticClass: "text-danger"
        }, [_vm._v(_vm._s(componentErrors[0]))])];
      }
    }])
  })], 1)], 1)], 1)], 1), _c('b-row', {
    staticClass: "mt-1"
  }, [_c('b-col', {
    attrs: {
      "lg": "3"
    }
  }, [_c('b-form-checkbox', {
    attrs: {
      "id": "invitation-bulk",
      "name": "checkbox-1"
    },
    model: {
      value: _vm.sendEmailInvitationBulk,
      callback: function callback($$v) {
        _vm.sendEmailInvitationBulk = $$v;
      },
      expression: "sendEmailInvitationBulk"
    }
  }, [_vm._v(" Send invitation? ")])], 1), _c('b-col', [_c('spinning-button', {
    attrs: {
      "is-loading": _vm.isLoadingBulk,
      "type": "submit",
      "variant": "primary"
    },
    nativeOn: {
      "click": function click($event) {
        return _vm.handleSubmitMultiple($event);
      }
    }
  }, [_vm._v(" Add participants in bulk ")])], 1)], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }