var render = function () {
  var _vm$tournament, _vm$tournament$discip, _vm$tournament2, _vm$tournament3, _vm$tournament4, _vm$tournament5, _vm$tournament6, _vm$tournament7, _vm$tournament7$disci, _vm$tournament8, _vm$tournament8$parti, _vm$tournament9, _vm$tournament10, _vm$tournament11, _vm$tournament12, _vm$tournament13, _vm$tournament15, _vm$tournament15$sett, _vm$tournament16, _vm$tournament17, _vm$tournament18, _vm$tournament19, _vm$tournament20, _vm$tournament21, _vm$tournament21$sett, _vm$tournament22, _vm$tournament23, _vm$disputes2, _vm$tournament25, _vm$tournament25$sett, _vm$tournament26, _vm$tournament27, _vm$tournament28, _vm$tournament29, _vm$tournament29$sett, _vm$tournament30, _vm$tournament31, _vm$tournament32, _vm$tournament33, _vm$tournament34, _vm$tournament34$part, _vm$tournament35, _vm$tournament36, _vm$tournament37, _vm$tournament38;
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_vm.loading ? _c('b-row', [_c('b-col', [_c('b-spinner', {
    attrs: {
      "label": "Loading..."
    }
  })], 1)], 1) : _c('b-row', [_c('b-col', {
    attrs: {
      "lg": "5",
      "md": "12"
    }
  }, [_c('b-card', [_c('b-row', [_c('b-col', {
    staticClass: "d-flex",
    attrs: {
      "lg": "12",
      "md": "12"
    }
  }, [_c('b-avatar', {
    attrs: {
      "src": (_vm$tournament = _vm.tournament) === null || _vm$tournament === void 0 ? void 0 : (_vm$tournament$discip = _vm$tournament.discipline) === null || _vm$tournament$discip === void 0 ? void 0 : _vm$tournament$discip.image,
      "variant": "light-primary",
      "size": "60px",
      "square": ""
    }
  }), _c('div', {
    staticClass: "mr-auto ml-1"
  }, [_c('h6', [_vm._v(_vm._s((_vm$tournament2 = _vm.tournament) === null || _vm$tournament2 === void 0 ? void 0 : _vm$tournament2.name))]), _c('organizer-tournament-status-badge', {
    attrs: {
      "status": (_vm$tournament3 = _vm.tournament) === null || _vm$tournament3 === void 0 ? void 0 : _vm$tournament3.status_id
    }
  })], 1), _c('div', {
    staticClass: "d-flex flex-column"
  }, [((_vm$tournament4 = _vm.tournament) === null || _vm$tournament4 === void 0 ? void 0 : _vm$tournament4.status_id) !== _vm.TournamentStatusType.IN_PROGRESS && ((_vm$tournament5 = _vm.tournament) === null || _vm$tournament5 === void 0 ? void 0 : _vm$tournament5.status_id) !== _vm.TournamentStatusType.FINISHED && ((_vm$tournament6 = _vm.tournament) === null || _vm$tournament6 === void 0 ? void 0 : _vm$tournament6.status_id) !== _vm.TournamentStatusType.CLOSED ? _c('b-button', {
    staticClass: "mb-1",
    attrs: {
      "variant": "primary"
    },
    on: {
      "click": _vm.redirectToEdit
    }
  }, [_vm._v(" Edit ")]) : _vm._e(), _c('b-button', {
    staticClass: "mb-1",
    attrs: {
      "variant": "primary"
    },
    on: {
      "click": _vm.openTournamentPage
    }
  }, [_vm._v(" Open ")])], 1)], 1)], 1), _c('b-row', {
    staticClass: "mt-1"
  }, [_c('b-col', {
    attrs: {
      "cols": "8"
    }
  }, [_c('b-row', [_c('b-col', {
    attrs: {
      "sm": "4"
    }
  }, [_vm._v(" Game: ")]), _c('b-col', [_c('strong', [_vm._v(_vm._s((_vm$tournament7 = _vm.tournament) === null || _vm$tournament7 === void 0 ? void 0 : (_vm$tournament7$disci = _vm$tournament7.discipline) === null || _vm$tournament7$disci === void 0 ? void 0 : _vm$tournament7$disci.title))])])], 1), _c('b-row', [_c('b-col', {
    attrs: {
      "sm": "4"
    }
  }, [_vm._v(" Participants: ")]), _c('b-col', [_vm._v(_vm._s(((_vm$tournament8 = _vm.tournament) === null || _vm$tournament8 === void 0 ? void 0 : (_vm$tournament8$parti = _vm$tournament8.participants) === null || _vm$tournament8$parti === void 0 ? void 0 : _vm$tournament8$parti.length) || 0) + " of " + _vm._s((_vm$tournament9 = _vm.tournament) === null || _vm$tournament9 === void 0 ? void 0 : _vm$tournament9.participants_number) + " " + _vm._s(((_vm$tournament10 = _vm.tournament) === null || _vm$tournament10 === void 0 ? void 0 : _vm$tournament10.participant_type_id) === _vm.TournamentParticipantType.TEAM ? 'teams' : 'players') + " joined ")])], 1), _c('b-row', [_c('b-col', {
    attrs: {
      "sm": "4"
    }
  }, [_vm._v(" Start: ")]), _c('b-col', [_vm._v(" " + _vm._s((_vm$tournament11 = _vm.tournament) === null || _vm$tournament11 === void 0 ? void 0 : _vm$tournament11.start_at) + " ")])], 1), _c('b-row', [_c('b-col', {
    attrs: {
      "sm": "4"
    }
  }), _c('b-col', [_vm._v(" " + _vm._s(_vm.stageTypeTitle) + " ")])], 1), _c('b-row', [_c('b-col', {
    attrs: {
      "sm": "4"
    }
  }, [_vm._v("Platforms: ")]), _c('b-col', _vm._l((_vm$tournament12 = _vm.tournament) === null || _vm$tournament12 === void 0 ? void 0 : _vm$tournament12.platforms, function (platform, index) {
    return _c('b-badge', {
      key: index,
      staticClass: "mr-1",
      attrs: {
        "size": "sm",
        "variant": "light-primary"
      }
    }, [_vm._v(" " + _vm._s(platform.title) + " ")]);
  }), 1)], 1), _c('b-row', [_c('b-col', {
    attrs: {
      "sm": "4"
    }
  }, [_vm._v("Check-in: ")]), _c('b-col', _vm._l((_vm$tournament13 = _vm.tournament) === null || _vm$tournament13 === void 0 ? void 0 : _vm$tournament13.platforms, function (platform, index) {
    var _vm$tournament14;
    return _c('b-badge', {
      key: index,
      staticClass: "mr-1",
      attrs: {
        "size": "sm",
        "variant": "light-primary"
      }
    }, [_vm._v(" " + _vm._s((_vm$tournament14 = _vm.tournament) !== null && _vm$tournament14 !== void 0 && _vm$tournament14.enable_checkin ? 'Yes' : 'No') + " ")]);
  }), 1)], 1)], 1), _c('b-col', {
    attrs: {
      "cols": "4"
    }
  }, [((_vm$tournament15 = _vm.tournament) === null || _vm$tournament15 === void 0 ? void 0 : (_vm$tournament15$sett = _vm$tournament15.settings) === null || _vm$tournament15$sett === void 0 ? void 0 : _vm$tournament15$sett.first_stage_structure) !== _vm.TournamentFormat.RACE && ((_vm$tournament16 = _vm.tournament) === null || _vm$tournament16 === void 0 ? void 0 : _vm$tournament16.status_id) >= _vm.TournamentStatusType.PREVIEW ? _c('b-row', {
    staticClass: "mb-1 justify-content-end"
  }, [_c('b-button', {
    attrs: {
      "variant": "primary"
    },
    on: {
      "click": _vm.redirectToBracket
    }
  }, [_vm._v(" Open bracket ")])], 1) : _vm._e(), _c('b-row', {
    staticClass: "mb-1 justify-content-end"
  }, [((_vm$tournament17 = _vm.tournament) === null || _vm$tournament17 === void 0 ? void 0 : _vm$tournament17.status_id) === _vm.TournamentStatusType.DRAFT ? _c('b-button', {
    attrs: {
      "variant": "info"
    },
    on: {
      "click": _vm.publishTournament
    }
  }, [_vm._v(" Publish tournament ")]) : _vm._e(), ((_vm$tournament18 = _vm.tournament) === null || _vm$tournament18 === void 0 ? void 0 : _vm$tournament18.status_id) === _vm.TournamentStatusType.PUBLISHED ? _c('b-button', {
    attrs: {
      "variant": "success"
    },
    on: {
      "click": _vm.previewTournament
    }
  }, [_vm._v(" Preview tournament ")]) : _vm._e(), ((_vm$tournament19 = _vm.tournament) === null || _vm$tournament19 === void 0 ? void 0 : _vm$tournament19.status_id) === _vm.TournamentStatusType.PREVIEW ? _c('b-button', {
    attrs: {
      "variant": "warning"
    },
    on: {
      "click": _vm.startTournament
    }
  }, [_vm._v(" Start tournament ")]) : _vm._e(), ((_vm$tournament20 = _vm.tournament) === null || _vm$tournament20 === void 0 ? void 0 : _vm$tournament20.status_id) === _vm.TournamentStatusType.FINISHED || ((_vm$tournament21 = _vm.tournament) === null || _vm$tournament21 === void 0 ? void 0 : (_vm$tournament21$sett = _vm$tournament21.settings) === null || _vm$tournament21$sett === void 0 ? void 0 : _vm$tournament21$sett.first_stage_structure) === _vm.TournamentFormat.RACE && ((_vm$tournament22 = _vm.tournament) === null || _vm$tournament22 === void 0 ? void 0 : _vm$tournament22.status_id) === _vm.TournamentStatusType.IN_PROGRESS ? _c('b-button', {
    attrs: {
      "variant": "primary"
    },
    on: {
      "click": _vm.closeTournament
    }
  }, [_vm._v(" Close tournament ")]) : _vm._e()], 1), ((_vm$tournament23 = _vm.tournament) === null || _vm$tournament23 === void 0 ? void 0 : _vm$tournament23.status_id) === _vm.TournamentStatusType.FINISHED ? _c('b-row', {
    staticClass: "justify-content-end"
  }, [_vm._v(" All matches finished please double check if all is OK and use this button to transfer funds to wining teams/players.. ")]) : _vm._e()], 1)], 1)], 1), _c('b-card', {
    attrs: {
      "title": "Activity"
    }
  }, [_c('b-tabs', {
    attrs: {
      "align": "left",
      "pills": ""
    }
  }, [_c('b-tab', {
    attrs: {
      "active": "",
      "title": "Logs"
    }
  }, [_vm._v(" No activity detected. ")]), _c('b-tab', {
    scopedSlots: _vm._u([{
      key: "title",
      fn: function fn() {
        var _vm$disputes;
        return [_c('span', [_vm._v("Disputes")]), ((_vm$disputes = _vm.disputes) === null || _vm$disputes === void 0 ? void 0 : _vm$disputes.length) > 0 ? _c('feather-icon', {
          staticClass: "ml-1",
          attrs: {
            "icon": "AlertTriangleIcon"
          }
        }) : _vm._e()];
      },
      proxy: true
    }])
  }, [!_vm.disputes || ((_vm$disputes2 = _vm.disputes) === null || _vm$disputes2 === void 0 ? void 0 : _vm$disputes2.length) === 0 ? _c('b-row', [_c('b-col', {
    attrs: {
      "sm": "12"
    }
  }, [_vm._v(" No open disputes. ")])], 1) : _vm._l(_vm.disputes, function (dispute) {
    var _vm$$route$params, _vm$tournament24, _dispute$opponent, _dispute$opponent2, _dispute$opponent2$pa, _dispute$opponent3, _dispute$opponent4, _dispute$opponent5, _dispute$opponent5$pa, _dispute$opponent6;
    return _c('b-row', {
      key: dispute === null || dispute === void 0 ? void 0 : dispute.id
    }, [_c('b-col', {
      attrs: {
        "sm": "12"
      }
    }, [_c('b-badge', {
      staticClass: "p-1",
      attrs: {
        "size": "sm",
        "variant": "light-danger",
        "to": {
          name: 'dashboard-tournament-matches',
          params: {
            id: (_vm$$route$params = _vm.$route.params) === null || _vm$$route$params === void 0 ? void 0 : _vm$$route$params.id,
            tournamentId: (_vm$tournament24 = _vm.tournament) === null || _vm$tournament24 === void 0 ? void 0 : _vm$tournament24.id
          }
        }
      }
    }, [_c('strong', [_vm._v(" " + _vm._s(dispute !== null && dispute !== void 0 && (_dispute$opponent = dispute.opponent1) !== null && _dispute$opponent !== void 0 && _dispute$opponent.participant ? dispute === null || dispute === void 0 ? void 0 : (_dispute$opponent2 = dispute.opponent1) === null || _dispute$opponent2 === void 0 ? void 0 : (_dispute$opponent2$pa = _dispute$opponent2.participant) === null || _dispute$opponent2$pa === void 0 ? void 0 : _dispute$opponent2$pa.name : dispute === null || dispute === void 0 ? void 0 : (_dispute$opponent3 = dispute.opponent1) === null || _dispute$opponent3 === void 0 ? void 0 : _dispute$opponent3.name) + " ")]), _vm._v(" vs "), _c('strong', [_vm._v(" " + _vm._s(dispute !== null && dispute !== void 0 && (_dispute$opponent4 = dispute.opponent2) !== null && _dispute$opponent4 !== void 0 && _dispute$opponent4.participant ? dispute === null || dispute === void 0 ? void 0 : (_dispute$opponent5 = dispute.opponent2) === null || _dispute$opponent5 === void 0 ? void 0 : (_dispute$opponent5$pa = _dispute$opponent5.participant) === null || _dispute$opponent5$pa === void 0 ? void 0 : _dispute$opponent5$pa.name : dispute === null || dispute === void 0 ? void 0 : (_dispute$opponent6 = dispute.opponent2) === null || _dispute$opponent6 === void 0 ? void 0 : _dispute$opponent6.name) + " ")])])], 1)], 1);
  })], 2)], 1)], 1), _c('b-card', [_c('label', {
    staticClass: "mb-2"
  }, [_vm._v(" These players were reported by other players. Try to investigate what happened and if you think that player deserves investigation by platform admins please use report button. Thank you for helping building better community. ")]), _c('div', [_vm._v("No reports.")])])], 1), _c('b-col', {
    attrs: {
      "lg": "4",
      "md": "12"
    }
  }, [_c('b-row', [_c('b-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('b-card', {
    attrs: {
      "title": "Current prize-pool"
    }
  }, [_c('label', [_vm._v(" When tournament is finished your will have to confirm results and your fee and prize-pool will be automatically transferred to players/teams and tournament will close. ")])])], 1)], 1), _c('organizer-tournament-race-results-modal', {
    attrs: {
      "tournament": _vm.tournament
    },
    on: {
      "onRaceResultUpdated": _vm.fetchTournament
    }
  }), ((_vm$tournament25 = _vm.tournament) === null || _vm$tournament25 === void 0 ? void 0 : (_vm$tournament25$sett = _vm$tournament25.settings) === null || _vm$tournament25$sett === void 0 ? void 0 : _vm$tournament25$sett.first_stage_structure) === _vm.TournamentFormat.RACE ? _c('b-row', [_c('b-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('b-card', {
    attrs: {
      "title": "Race results"
    }
  }, [_c('div', [_c('b-table', {
    ref: "refRaceResultsTable",
    attrs: {
      "busy": _vm.isLoading,
      "fields": _vm.raceResultsTableCols,
      "items": _vm.raceResults,
      "empty-text": "No race results yet",
      "primary-key": "id",
      "responsive": "",
      "show-empty": ""
    },
    on: {
      "update:busy": function updateBusy($event) {
        _vm.isLoading = $event;
      }
    },
    scopedSlots: _vm._u([{
      key: "cell(participant)",
      fn: function fn(data) {
        return [_vm._v(" " + _vm._s(data.item.participant ? data.item.participant.name : data.item.name) + " ")];
      }
    }, {
      key: "cell(rank)",
      fn: function fn(data) {
        return [_vm._v(" " + _vm._s(data.index + 1) + " ")];
      }
    }, {
      key: "cell(elapsed_time)",
      fn: function fn(data) {
        return [_vm._v(" " + _vm._s(data.item.elapsed_time ? _vm.millisecondsToTime(data.item.elapsed_time) : 'no result yet') + " ")];
      }
    }, {
      key: "table-busy",
      fn: function fn() {
        return [_c('div', {
          staticClass: "text-center"
        }, [_c('b-spinner', {
          staticClass: "align-middle mr-2"
        }), _c('strong', [_vm._v("Loading...")])], 1)];
      },
      proxy: true
    }], null, false, 2573609376)
  })], 1), ((_vm$tournament26 = _vm.tournament) === null || _vm$tournament26 === void 0 ? void 0 : _vm$tournament26.status_id) !== _vm.TournamentStatusType.CLOSED ? _c('div', [_c('b-button', {
    attrs: {
      "variant": "primary",
      "size": "sm"
    },
    on: {
      "click": _vm.handleOpenSaveRaceResultsClick
    }
  }, [_vm._v(" Update race results ")])], 1) : _vm._e()])], 1)], 1) : _c('b-row', [_c('b-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('b-card', {
    attrs: {
      "title": "Live matches"
    }
  }, [_c('div', {
    staticClass: "mb-2"
  }, [_c('label', [_vm._v("No live matches.")])]), _c('div', [_c('b-button', {
    attrs: {
      "variant": "primary",
      "size": "sm"
    },
    on: {
      "click": _vm.handleOpenMatchesClick
    }
  }, [_vm._v(" All matches ")])], 1)])], 1)], 1), (((_vm$tournament27 = _vm.tournament) === null || _vm$tournament27 === void 0 ? void 0 : _vm$tournament27.status_id) === _vm.TournamentStatusType.FINISHED || ((_vm$tournament28 = _vm.tournament) === null || _vm$tournament28 === void 0 ? void 0 : _vm$tournament28.status_id) === _vm.TournamentStatusType.CLOSED) && ((_vm$tournament29 = _vm.tournament) === null || _vm$tournament29 === void 0 ? void 0 : (_vm$tournament29$sett = _vm$tournament29.settings) === null || _vm$tournament29$sett === void 0 ? void 0 : _vm$tournament29$sett.first_stage_structure) !== _vm.TournamentFormat.RACE ? _c('b-row', [_c('b-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('b-card', {
    attrs: {
      "title": "Results"
    }
  })], 1)], 1) : _vm._e()], 1), _c('b-col', {
    attrs: {
      "lg": "3",
      "md": "12"
    }
  }, [_c('b-card', {
    attrs: {
      "title": "Staff"
    }
  }, _vm._l((_vm$tournament30 = _vm.tournament) === null || _vm$tournament30 === void 0 ? void 0 : _vm$tournament30.staff, function (member) {
    return _c('b-badge', {
      key: member.user.id,
      staticClass: "mr-1",
      attrs: {
        "variant": "light-primary"
      }
    }, [_vm._v(" " + _vm._s(member.user.name) + " ")]);
  }), 1), _c('organizer-tournament-participant-modal', {
    attrs: {
      "participant-type": (_vm$tournament31 = _vm.tournament) === null || _vm$tournament31 === void 0 ? void 0 : _vm$tournament31.participant_type_id,
      "players-per-team": (_vm$tournament32 = _vm.tournament) === null || _vm$tournament32 === void 0 ? void 0 : _vm$tournament32.players_per_team
    },
    on: {
      "onParticipantsAdded": _vm.fetchTournament
    }
  }), _c('b-card', {
    attrs: {
      "title": "Participants"
    }
  }, [!((_vm$tournament33 = _vm.tournament) !== null && _vm$tournament33 !== void 0 && _vm$tournament33.participants) || ((_vm$tournament34 = _vm.tournament) === null || _vm$tournament34 === void 0 ? void 0 : (_vm$tournament34$part = _vm$tournament34.participants) === null || _vm$tournament34$part === void 0 ? void 0 : _vm$tournament34$part.length) == 0 ? _c('label', {
    staticClass: "font-italic"
  }, [_vm._v(" No participants ")]) : _vm._e(), ((_vm$tournament35 = _vm.tournament) === null || _vm$tournament35 === void 0 ? void 0 : _vm$tournament35.enable_registration) === false ? _c('label', [_c('p', [_vm._v("Your tournament dose not have registrations open so please use button below to add participants!")])]) : _vm._e(), _c('div', [(_vm$tournament36 = _vm.tournament) !== null && _vm$tournament36 !== void 0 && _vm$tournament36.participants && ((_vm$tournament37 = _vm.tournament) === null || _vm$tournament37 === void 0 ? void 0 : _vm$tournament37.participants.length) > 0 ? _c('ul', {
    staticClass: "list-unstyled d-flex flex-wrap"
  }, _vm._l(_vm.tournament.participants, function (participant) {
    return _c('li', {
      key: participant.id
    }, [participant.participant_type === _vm.TournamentParticipantType.TEAM ? _c('b-badge', {
      staticClass: "m-1",
      attrs: {
        "to": participant.participant ? {
          name: 'team-profile',
          params: {
            id: participant.participant_id
          }
        } : null,
        "variant": "light-primary"
      }
    }, [_vm._v(" " + _vm._s(participant.participant ? participant.participant.name : participant.name) + " ")]) : _c('b-badge', {
      staticClass: "m-1",
      attrs: {
        "to": participant.participant ? {
          name: 'player-profile',
          params: {
            id: participant.participant_id
          }
        } : null,
        "variant": "light-primary"
      }
    }, [_vm._v(" " + _vm._s(participant !== null && participant !== void 0 && participant.participant ? participant.participant.name : participant.name) + " ")])], 1);
  }), 0) : _vm._e()]), ((_vm$tournament38 = _vm.tournament) === null || _vm$tournament38 === void 0 ? void 0 : _vm$tournament38.status_id) < _vm.TournamentStatusType.IN_PROGRESS ? _c('div', {
    staticClass: "d-flex align-items-start flex-column"
  }, [_c('b-button', {
    attrs: {
      "variant": "outline-primary",
      "size": "sm"
    },
    on: {
      "click": _vm.handleOpenParticipantsClick
    }
  }, [_vm._v(" Manage participants ")])], 1) : _vm._e()])], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }